/* gallery styles */
.gallery {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 10px;
}
/* for 1 : 1 images of grid */
/* .grid-item img {
    aspect-ratio: 1 / 1;
    object-fit: cover;
} */

.gallery-item, .grid-item {
    box-sizing: border-box;
    margin: 0;
}
.gallery-item img, .grid-item img {
    width: 100%;
}

.modal {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.9);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    position: relative;
    max-width: 80%;
    max-height: 80%;
}

.modal img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.modal .close {
    position: absolute;
    top: 10px;
    right: 25px;
    color: #fff;
    font-size: 40px;
    cursor: pointer;
}

.ck-grid-square-hovered {
    background-color: #3e3e3e !important;
}

.ck-grid-square-selected {
    background-color: #3e3e3e !important;
}